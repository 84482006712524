@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 5%;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.company-logo {
    width: 150px;
    height: auto;
    justify-content: left;
}

header h1 {
    font-size: 40px;
    color: white;
}

header nav {
    display: flex;
    gap: 10px;
}

header nav a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 99px;
    transition: 0.3s;
    color: white;
    cursor: pointer;
}

header nav a:hover {
    background: #2A2F4F;
}

header nav:hover>a:not(:hover) {
    background: none;
}

.home {
    display: flex;
    height: 100dvh;
    background: linear-gradient(to top, #D2E2FB 30%, #86A8DB);
    position: relative;
    overflow: hidden;
}

.home img {
    position: absolute;
    bottom: 0;
    width: 100%;
    pointer-events: none;
}

.home .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 90px;
    font-weight: lighter;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.about {
    padding: 50px;
    background: #DBEDFF;
    color: black;
    position: relative;
    z-index: 100;
}

.about-text {
    max-width: 100%;
    overflow-x: auto;
}

.advantage-container {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: thin;
    padding: 20px;
}

.advantage-card {
    flex: 0 0 auto;
    background-color: #fff;
    color: black;
    width: 300px;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.advantage-card:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.about h1 {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
}

.about h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.about h3 {
    font-size: 20px;
    font-weight: bold;
}

.about p {
    margin-bottom: 15px;
}

.featured-products h1 {
    text-align: center;
    font-size: 30px;
}

.featured-products {
    background: #F8F9F9;
    color: black;
    padding: 50px;
    width: 100%;
    text-align: center;
}

.product-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.product {
    text-align: center;
    flex: 0 0 30%;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.product:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.product img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.our-team h1 {
    text-align: center;
    font-size: 30px;
}

.our-team {
    color: black;
    background-color: #F8F9F9;
    padding: 20px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease;
}

.our-team:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.our-team-container {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
}

.our-team {
    text-align: center;
    flex: 0 0 30%;
}

.our-team img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.contact-us {
    padding: 30px;
    width: 100%;
    background-color: #F8F9F9;
}

.contact-us h2 {
    font-size: 30px;
    margin-bottom: 15px;
}

.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #DBEDFF;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}

.name-group,
.contact-info-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
}

h2 {
    text-align: center;
    color: #333;
}

textarea {
    height: 100px;
}

.submit-button {
    background-color: #956dec;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #6D31ED;
    transition: background 0.5s ease;
}


input,
textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.footer-section {
    background: linear-gradient(to right, #ffffff, #6D31ED);
    padding: 20px 0;
    color: #fff;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
}

.company-logo {
    width: 180px;
    height: 100px;
    margin-right: 10px;
}

.dropdown {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 99px;
    transition: 0.3s;
    color: white;
    cursor: pointer;
  }

  .dropdown:hover>a:not(:hover) {
    background: none;
  }

  .dropdown:hover {
    background: #2A2F4F;
}
  
  .sign-in-link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 99px;
    transition: 0.3s;
    color: white;
    cursor: pointer;
  }
  
  .dropdown-content {
    padding: 8px 16px;
    display: none;
    position: absolute;
    top: calc(75%);
    left: calc(85%);
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background 0.3s;
    border-radius: 10px;
    margin-top: 5px;
  }
  
  .dropdown-content a:hover {
    background-color: #2A2F4F;
    color: white;
  }

@media only screen and (max-width: 768px) {
    /* Header */
    header {
        height: 80px;
        padding: 0 2%;
    }
    /* Navigasi */
    header nav {
        flex-direction: column;
    }
    /* Navigasi link */
    header nav a {
        padding: 8px 12px;
        font-size: 18px;
    }
    /* Home */
    .home .title {
        font-size: 30px;
    }
    .our-team-container {
        flex-direction: column;
    }
    .product-container {
        flex-direction: column;
    }
}